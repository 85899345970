var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"font-weight-bold pb-1 primary--text"},[_vm._v("Gestión")]),_c('h5',{staticClass:"font-weight-light pb-3"},[_vm._v("Gestión de Proyectos")]),_c('v-card',{staticClass:"elevation-15",attrs:{"id":"cardborde"}},[_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","top":"","color":"verde"}}),_c('v-card-title',{staticClass:"font-weight-light"},[_vm._v(" Lista de Proyectos "),_c('v-spacer'),_c('v-text-field',{staticClass:"m-2",attrs:{"append-icon":"mdi-magnify","color":"primary","label":"Buscar","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1),(_vm.verHitos)?_c('v-tabs',{attrs:{"right":""}},[_c('v-tab',{on:{"click":function($event){_vm.bEstado = 'Proyectos'}}},[_vm._v("Proyectos")]),_c('v-tab',{on:{"click":function($event){_vm.bEstado = 'ITO'}}},[_vm._v("ITO")]),_c('v-tab',{on:{"click":function($event){_vm.bEstado = 'Todos'}}},[_vm._v("Todos")])],1):_vm._e(),_c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"no-results-text":_vm.noresult,"no-data-text":"Sin resultados que mostrar","search":_vm.search,"custom-filter":_vm.filterOnlyCapsText,"sort-by":['nro_proyecto'],"sort-desc":[true],"custom-sort":_vm.customSort,"footer-props":{
        'items-per-page-text': 'Resultados por página',
        'items-per-page-all-text': 'Todos',
      }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}},{key:"item.url",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.verURL(item.url)}}},[_vm._v(" mdi-web ")])]}},{key:"item.accion",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"mr-2 p-1",attrs:{"color":"primary","text-color":"verde","small":""}},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item._id)}}},[_vm._v(" mdi-pencil ")])],1),_c('v-chip',{staticClass:"mr-2 p-1",attrs:{"color":"primary","text-color":"red","small":""}},[(_vm.usuarioDB.data.role == 'ADMIN')?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item._id)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","small":"","label":"","outlined":"","color":_vm.getEstados(item.status)}},[_c('b',[_vm._v(" "+_vm._s(item.status)+" ")])])]}},{key:"item.mandante",fn:function(ref){
      var item = ref.item;
return [(item.obras)?_c('div',[_c('v-chip',{staticClass:"ma-0 p-1",attrs:{"dark":"","x-small":"","label":"","color":"green"}},[_c('b',[_vm._v("M")])]),_vm._v(" "+_vm._s(item.mandante)),_c('br'),_c('v-chip',{staticClass:"ma-0 p-1",attrs:{"dark":"","x-small":"","label":"","color":"brown"}},[_c('b',[_vm._v("O")])]),_vm._v(" "+_vm._s(item.obra)+" ")],1):_c('div',[_vm._v(" "+_vm._s(item.mandante)+" ")])]}},{key:"item.responsable",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","small":"","color":item.color,"data-toggle":"tooltip","data-placement":"bottom","title":item.responsableCompleto}},[_vm._v(" "+_vm._s(item.responsable)+" ")])]}}])})],1),_c('v-snackbar',{attrs:{"color":"green","dark":"","timeout":"1500","align":"center"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" URL copiada exitosamente! ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }